<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import CustomWebBuilder from "@/components/personalizations/customWebBuilder";
import CustomJsonBuilder from "@/components/personalizations/customJsonBuilder";
import CustomJsBuilder from "@/components/personalizations/customJSBuilder";
import Templates from  "@/components/personalizations/templates";
import Drawer from "vue-simple-drawer";

import {
	templateMethods,
  propertyMethods,
  projectMethods
} from "@/state/helpers";

/**
 * Audience component
 */
export default {
  page: {
    title: "New Template",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect, CustomWebBuilder, CustomJsonBuilder, CustomJsBuilder, Templates, Drawer },
  data() {
    return {
      title: '',
      template:{
      },
      submitted: false,
			types: [
        {id: "web", label: this.$t("personalizations.type_web")},
        {id: "recommendations", label: this.$t("personalizations.type_reco")},
        {id: "custom_code", label: this.$t("personalizations.type_custom_code") },
        {id: "pop_up", label: this.$t("personalizations.type_pop_up") },
        {id: "notifications", label: this.$t("personalizations.type_notifications") },
        {id: "feedback", label: "Feedback" },
        {id: "API", label: "API" },
        {id: "email", label: "Email" },
      ],
      auxType : null,
      affinity_Types: [],
      items: [],
      properties_products: [],
      empty_config:{
        title: "Selecciona un template para comenzar",
        subtitle: "Seleccioná uno de nuestros templates que mejor se adecúe a tu necesidad o creá uno desde 0",
        buttonText: "Seleccionar Template",
        icon: "fa-cubes"
      },
      templateLoaded: false,
      openRightDrawer:false,
      initialValues: {
        "html":"",
        "css": "",
        "js": "",
        variables:[],
        groups:[]
      },
      selectType: 'web',
      categories:[]
    };
  },
  validations: {
    template: {
        name: { required},
        type: { required},
    }
  },
  mounted(){
    const templateId = this.$route.query?.id;
    this.title = templateId ? '' : this.$t('templates.new_template')
    if(templateId){
      let loader = this.$loading.show();
      this.getTemplate(templateId).then((res)=>{
        this.template = res.data;
        this.title = this.template.name;
        this.auxType = this.types.filter(t=> t.id == this.template.type)[0];
        this.selectType = this.template.type;
        if(this.template.type == 'web' || this.template.type=='recommendations' || this.template.type == 'pop_up' || this.template.type == 'notifications' || this.template.type == 'email' || this.template.type == 'feedback')
          this.$refs.customWebBuilder.setTemplateValue(this.template.value);
        else if(this.template.type == 'API')
          this.$refs.customJsonBuilder.setTemplateValue(this.template.value);
        else if(this.template.type == 'custom_code'){
          this.$refs.customJsBuilder.setTemplateValue(this.template.value);
        }

      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('templates.get_template_error'),title:  "Templates" });
      }).finally(()=>{
        loader.hide();
      })

    }else {
      this.auxType = this.types[0];
      this.template.type = this.auxType.id
      
      this.$refs.customWebBuilder.setTemplateValue(this.initialValues);
      this.$refs.customJsonBuilder.setTemplateValue({"json": "{}"});
      this.$refs.customJsBuilder.setTemplateValue({js: '', css:''});
    }

    this.loadItems();
    this.loadProperties();
    this.loadCategories();
  },
  computed:{
    
  },
  methods:{
    ...templateMethods,
    ...propertyMethods,
    ...projectMethods,

    onTypeChanged(){
      this.template.type = this.auxType.id;
      this.selectType = this.auxType.id;
    },
    rightDrawer() {
          this.openRightDrawer = !this.openRightDrawer;
      },

    onTemplateClicked(template){
      this.openRightDrawer = false;

      if(this.template.type == 'web' || this.template.type=='recommendations' || this.template.type == 'pop_up' || this.template.type == 'notifications' || this.template.type== 'email' || this.template.type == 'feedback')
        this.$refs.customWebBuilder.setTemplateValue(template);
      else if(this.template.type == 'API')
        this.$refs.customJsonBuilder.setTemplateValue(template);
      else if(this.template.type == 'custom_code'){
        this.$refs.customJsBuilder.setTemplateValue(template);
      }
    },
    onImportTemplateClicked(){
      this.openRightDrawer = true;
    },
    loadItems(){
        
				const params = {
					q: `whereObj={"project":"${localStorage.getItem('current_project')}", "data.active":1}&limit=50`
				}

        this.getItems(params).then((res)=>{
					this.items = res.data;
        }).catch(()=>{
					this.$notify({ type: 'error', text: this.$t('items.list_get_error'),title:  this.$t('items.title') });
				}).finally(()=>{
					
				})
		},

    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        this.properties_products = properties.filter(p=> p.source == 'product_catalog');
        this.affinity_Types = this.properties_products.filter(p=> p.type== 'list');

				this.affinity_Types.push({
          type : 'product_category',
          name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
          column_name: 'product_category'
        });

      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
    
    loadCategories(){
      const params = {
				q : `project=${localStorage.getItem("current_project")}`
			}
			
			this.getItemsCategories(params).then((categories)=>{
				this.categories = categories;
			}).catch(()=>{

			}).finally(()=>{
				
			})
		},

    onCancelClicked(){
        this.$router.push({
            path: "/templates",
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    async onConfirmClicked(){
      this.submitted = true;
        
      this.$v.$touch();

      if (this.$v.$invalid) {
				return;
			}
      
      if(this.template.type == 'web' || this.template.type=='recommendations' || this.template.type == 'pop_up' || this.template.type == 'notifications' || this.template.type == 'email' || this.template.type == 'feedback')
          this.template.value = this.$refs.customWebBuilder.getTemplatesValues();
        else if(this.template.type == 'API')
        this.template.value = this.$refs.customJsonBuilder.getTemplatesValues();
        else if(this.template.type == 'custom_code'){
          this.template.value = this.$refs.customJsBuilder.getTemplatesValues();
        }

      if(this.template._id){
        let loader = this.$loading.show();
        this.updateTemplate(this.template).then(()=>{
          this.$notify({ type: 'success', text: this.$t('templates.update_template_success'),title:  "Templates" });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('templates.update_template_error'),title:  "Templates" });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        let loader = this.$loading.show();
        this.createTemplate(this.template).then(()=>{
          this.$notify({ type: 'success', text: this.$t('templates.create_template_success'),title: "Templates"  });
          this.$router.push({
              path: "/templates",
              }
          ).catch(() => {});
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('templates.create_template_error'),title:  "Templates"  });
      }).finally(()=>{
        loader.hide();
      })
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="[]" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" >
            <div class="row" >
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label for="promotionName">{{$t('templates.name')}}*</label>
                        <input
                            id="promotionName"
                            v-model="template.name"
                            name="name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('templates.name_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.template.name.$error}"
                        />
                        <div v-if="submitted && !$v.template.name.required" class="invalid-feedback">
                          {{ $t('templates.name_required') }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label for="promotionName">{{ $t('templates.type') }}*</label>
                        <multiselect
                          :disabled="template?._id != null"
                          :options="types" 
                          v-model="auxType"
                          track-by="id" 
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="form control"
                          :placeholder="$t('templates.type_placeholder')"
                          :class="{ 'is-invalid': submitted && $v.template.type.$error}"
                          @input="onTypeChanged"
                        />
                        <div v-if="submitted && !$v.template.type.required" class="invalid-feedback">
                          {{ $t('templates.type_required') }}
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
			<!-- end col -->
       <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-10"></div>
            <button class="btn btn-outline-primary col-2" @click="onImportTemplateClicked">{{$t('templates.select_template')}}</button>
          </div>
          
          <CustomWebBuilder ref="customWebBuilder" :items="items" v-show="auxType?.id=='web' || auxType?.id=='recommendations' || auxType?.id=='pop_up' || auxType?.id=='notifications' || auxType?.id=='email' || auxType?.id=='feedback'  " :affinity_Types="affinity_Types" :categories="categories" :email="auxType?.id=='email'" :devMode="true"/>
          <CustomJsonBuilder ref="customJsonBuilder" v-show="auxType?.id=='API'" :items="items" :affinity_Types="affinity_Types" :categories="categories" />
          <CustomJsBuilder ref="customJsBuilder" v-show="auxType?.id=='custom_code'" :categories="categories" :items="items" :affinity_Types="affinity_Types" />
        </div>
       </div>
       <Drawer
            @close="rightDrawer"
            :align="'right'"
            :closeable="true"
            :maskClosable="true"
            :zIndex="1002">
              <Templates ref="TemplatesComponent" :channel="template.type == 'API' ? 'API' : 'web'" :type="selectType"  v-if="openRightDrawer" @selectTemplate="onTemplateClicked" :email="template.type=='email'"></Templates>
      </Drawer>
    <!-- end row -->
    </div>
    </div>
    <div class="row mt-4">
				<div class="col-sm-6">
				</div>
				<!-- end col -->
				<div class="col-sm-6">
					<div class="text-end">
						<b-button variant="light" class="w-md mb-3" @click="onCancelClicked">{{$t("common.cancel")}}</b-button>
						<b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmClicked">{{$t("common.confirm")}}</b-button>
					</div>
				</div>
				<!-- end col -->
		</div>  
  </Layout>
</template>
<style>

.vars-panel::-webkit-scrollbar {
  display: none;
}

#myIframe {
  height: 600px !important;
}
.vars-panel {
  height: 550px;
  overflow-y: scroll;
}
.interact {
	cursor: pointer;
}

.vue-simple-drawer{ 
    min-width: 500px !important;
}
</style>